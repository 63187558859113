import { Injectable } from '@angular/core';
import { Paginator, SortDirection } from '@app/classes';
import { ProjectAssignment } from '@app/models/time-tracking/project-assignment.model';
import { TimesheetEntry } from '@app/models/time-tracking/timesheet-entry.model';
import { Table } from '@app/modules/table/classes/table.class';
import { AuthService } from '@app/services';
import { DescriptionColumn, HoursColumn, NameColumn } from './columns';

@Injectable()
export class MyProjectsIndexTable extends Table {
    showMenuColumnOptions = true;
    sortDirection = SortDirection.DESC;
    links = true;

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    getLink(row: TimesheetEntry): (string | number)[] {
        return [row.id];
    }

    setColumns(): void {
        const columns = [new NameColumn(), new DescriptionColumn(), new HoursColumn()];

        this.columns = columns;
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            ProjectAssignment.with(['project', 'employee']).where('employeeId', this.auth.employee.id)
        );
    }
}
