import { Paginator, Platform, SortDirection } from '@app/classes';
import { TimesheetEntry } from '@app/models/time-tracking/timesheet-entry.model';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { AuthService } from '@app/services';
import { PayrollIncomeType } from '@time-tracking/interfaces/settings.interface';
import {
    DateColumn,
    HoursColumn,
    HourTypeColumn,
    NoteColumn,
    ProjectColumn,
    StartEndTimeColumn,
    StatusColumn,
} from './columns';

const deleteOption: MenuColumnItem = {
    label: 'time-tracking.delete',
    event: 'delete',
};

const editOption: MenuColumnItem = {
    label: 'time-tracking.edit',
    event: 'edit',
};

export class MyTimesheetsIndexTable extends Table {
    showMenuColumnOptions = true;
    sortDirection = SortDirection.DESC;
    links = true;

    constructor(
        protected auth: AuthService,
        private hasPayrollIntegration: boolean,
        private hasTimeClock: boolean,
        private payrollIncomeTypes: PayrollIncomeType[] = []
    ) {
        super(auth);
        this.boot();
    }

    getLink(timesheetEntry: TimesheetEntry): (string | number)[] {
        // only admins can edit approved timesheets
        if (this.auth.cant(Platform.timeTracking.permission.view) && timesheetEntry.isApproved) {
            return [];
        }

        return [timesheetEntry.id, 'edit'];
    }

    setColumns(): void {
        const dateColumn = new DateColumn();

        this.sortColumn = dateColumn;

        const columns = [dateColumn, new ProjectColumn(this.auth.employee.id), new NoteColumn()];

        if (this.hasPayrollIntegration) {
            columns.push(new HourTypeColumn(this.payrollIncomeTypes));
        }

        if (this.hasTimeClock) {
            columns.push(new StartEndTimeColumn());
        }

        columns.push(...[new HoursColumn(), new StatusColumn()]);

        this.columns = columns;
    }

    getMenuColumnOptionsForRow(timesheetEntry: TimesheetEntry): MenuColumnItem[] {
        if (this.auth.can(Platform.timeTracking.permission.view) || !timesheetEntry.isApproved) {
            return [editOption, deleteOption];
        }

        return [];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            TimesheetEntry.with(['project', 'employee', 'timeClockEntry']).where('employeeId', this.auth.employee.id)
        );
    }
}
